@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');



body {
  background-color: #f8f9fc !important;
  font-family: 'Rubik';
}

a {
  color: #878fa4;
}

p {
  font-size: 0.9rem;
}

iframe {
  width: 100%;
  border-radius: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grid-list {
  margin: 0 auto;
}

.card {
  display: flex;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark-section {
  background: black;
  padding: 50px;
}

.pad-10 {
  padding: 10px;
}

.newsCardImage {
  width: 100%;
  height: 250px;
  background-size: cover;
  border-radius: 10px;
}

.content-holder {
  position: relative;
background: white;
margin-top: -10px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.station-logo {
  width: 200px;
  height: 200px;
  margin-top: 10px;
  margin-left: 50px;
  }


  .np-img {
    height: 50px;
    width: 50px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    float: left;
    margin-top: 10px;
    }

.headlight-article {
  margin-top: 10px;
  font-family: 'Rubik';
  padding: 10px;
  font-size: 200px;
  font-weight: 600;
}

.section-title {
margin-top: 10px;
margin-bottom: 10px;
font-size: 18px;
color: black;
font-weight: bold;
}

.hero-title {
  float: left;
max-width: 300px !important;
color: white;
font-size: 1.3rem;
display: table;
word-wrap: break-word;
white-space: pre-line;
padding: 10px;
bottom: 0;
position: absolute;
font-family: Rubik, Arial, sans-serif;
font-weight: bold;
}

.dark-overlay {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 10px;
  bottom: 0;
}

.featuredDJHeader {
  object-fit: center;
}

.nav1 {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 60px;
  background: rgb(255, 44, 94) !important;
  z-index: 200;

}

.hover-icon:hover {
  background: rgb(240, 6, 61)!important;
  border-radius: 10px;
  
}

.view {
  margin: 0 auto;
  padding-left: 60px;
  padding-bottom: 150px;
  padding-top: 60px;
}

.mw-850 {
  max-width: 850px;
  margin: 0 auto;
}

.mw-1080 {
  max-width: 1080px;
  margin: 0 auto;
}

.padding-left-60 {
  padding-left: 60px;
}


.player {
position: fixed;
bottom: 0;
width: 100%;
height: 80px;
border-radius: 10px;
background: #f8f9fc !important;
margin-top: 10px;
margin: 0 auto;
box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
padding-top: 10px;
padding-left: 15px;
z-index: 300;
}

.player_inner {
  float: left;
  position: absolute;
  bottom: 20px;
}

.pad {
height: auto;
margin: 0 auto -100px;
min-height: 100%;
padding: 10px 10px 100px 10px;
box-sizing: border-box;
overflow: auto;
}

.last10 {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  }

  .item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 22px 4px rgba(0,0,0,.07);
    flex: 0 48%;
    height: 70px;
    margin-bottom: 2%;
    max-height: 100px;
    transition: all .25s;
    position: relative;
    max-width: 48%;
    }

    .item-holder {
      display: flex;
      margin-left: 10px;
      margin-top: 10px;
      position: relative;
      max-width: calc(100% - 100px);
      }

  .item:hover {
	transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
	margin: 10px;
  }
  

  .np-info {
    padding-left: 60px;
    }

  .np-info-container {
  height: 100%;
   max-width: 88%;
  }

  .songinfo {
    height: 100%;
    display: flex;
    max-height: 75px;
    }

  .nav-items {
    margin-left: 5px !important;
  }

  .mobilenav {
    display: none;
  }
  

  #overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 75% !important; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.9); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }


  .overlay {


  }

  .true {
    display: block;
    background-color: #e5e7ec;
    position: fixed;
    height: 100vh;
    width: 70%;
  }

  .false {
    display: none;
  }



  /* sidebar Nav */

.navBar {
  position: relative;
}

.navBar button {
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 10;
  cursor: pointer;
}

.menuNav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  background-color: rgb(0,0,0);
  background-color: #e5e7ec;
  overflow-x: hidden;
  transition: 0.5s;

}

.menuNav.showMenu {
  width: 75%;
}


.menuNav li:first-child {
  margin-top: 7rem;
}

.menuNav li {
  margin-left: 25%;
}

.closeToggle {
  position: relative;
  float: right;
}

select {
  height: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid lightgrey;
  padding: 7px 35px 7px 14px;
  margin: 0px 0px 0px 5px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  font-family: "Rubik";
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  background-image: url(https://megahit.live/img/down.png);
  background-size: 10px 6px;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-position: calc(100% - 14px) center;
  background-repeat: no-repeat no-repeat;
  -webkit-appearance: none;
  margin-bottom: 20px;
  }


  .now-on-air {
    height: 80px;
    box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);

    }

  .now-on-air-artist {
    font-size: 16;
    font-weight: bold;
    color: black;
    font-family: Rubik;

  }

    .now-on-air-info {
      float: left;
      margin-left: 10px;
      font-weight: bold;
      /* color: #878fa4; */
      font-family: 'Rubik', sans-serif;
      }

      .last10-artist-title {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding-left: 10px;
        margin-top: 5px;
        }

      .last10-artist {
        font-size: 12px;
        color: #878fa4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }

      .last10-title {
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1px;
        font-family: Rubik,Arial,sans-serif;
        font-size: 14px;
        font-weight: bold;
  
        }


        .on-air-holder {
          position: fixed;
width: 100%;
background-color: white;
z-index: 200;
        }

    @media only screen 
    and (max-width : 736px) {

      .disable-padding-left-60-mobile {
        padding-left: 0px;
      }

      .newsCardImage {
        width: 100%;
        height: 350px;
        background-size: cover;
        border-radius: 10px;
      }

      .on-air-holder {
        display: none;
      }

      .now-on-air-artist {
        font-size: 12px;
        font-weight: bold;
        color: black;
        font-family: Rubik;
    
      }


      .now-on-air-title {
        font-size: 10px;
        color: #878fa4;
        font-family: Rubik;
    
      }

      .last10-artist {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1;
        font-size: 14px;
        font-family: 'Rubik', sans-serif;
  
        }
  
        .last10-title {
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 1;
          font-size: 14px;
          font-family: 'Rubik', sans-serif;
    
          }

      .mobilenav {
        display: block;
        float: right;
        width: 100%;
        position: relative;

      }

      .mobile-toggle {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .nav1 {
        position: fixed;
        left: 0;
        top: 0;
        height: 75px;
        width: 100vw;
        background: white !important;
        z-index: 5;
      }

      .nav-items {
        display: none;
      }

      .station-logo {

        width: 70px;
        height: 70px;
        margin-top: 3px;
        margin-left: 10px;
      }

      .pad {
		height: auto;
    margin: 0 auto -120px; /* footer height + space */
    min-height: 100%;
    padding: 0px 10px 120px 10px; /* footer height + space */
    box-sizing: border-box;
    overflow: auto;

	}

  .view {
		margin: 0 auto;
		max-width: 640px;
		padding-left: 0px;
		padding-right: 0px;
    padding-top: 60px;
	}

  .item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 22px 4px rgba(0,0,0,.07);
    flex: 0 100%;
    height: 70px;
    margin-bottom: 2%;
    max-height: 100px;
    transition: all .25s;
    position: relative;
    max-width: 100%;
    }
  .menuInner {
  margin-left: -60px;
  }

  .now-on-air-info {
    margin-left: 10px !important;
  }
    }
    